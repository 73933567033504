import { Sucursal } from "./sucursal.model";


export class Periodo {

    public idPeriodo: number;
    public nombre: string;
    public fechaInicio: Date;
    public fechaFin: Date;
    public fechaInicioLarga: string;
    public fechaFinLarga: string;
    public status: boolean;
    public sucursal: Sucursal;


    constructor(
        idPeriodo?: number,
        nombre?: string,
        fechaInicio?: Date,
        fechaFin?: Date,
        fechaInicioLarga?: string,
        fechaFinLarga?: string,
        status?: boolean,
        sucursal?: Sucursal,

    ) {

        this.idPeriodo = idPeriodo;
        this.nombre = nombre;
        this.fechaInicio = fechaInicio;
        this.fechaFin = fechaFin;
        this.fechaInicioLarga = fechaInicioLarga;
        this.fechaFinLarga = fechaFinLarga;
        this.status = status;
        this.sucursal = sucursal;
    }

}


